@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';
























































































@import '@/assets/scss/_colors.scss';
.google-totp-login-btns {
  button {
    flex: 0 1 49%;
  }
}

.google-totp-login-row {
  height: 30px;
  max-width: 390px;
  margin: 60px auto;

  & > ul {
    padding: 0 !important;
    display: grid !important;
    list-style-type: none;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0 8px;

    & > li {
      padding-bottom: 6px;
      position: relative;

      &:before {
        content: '';
        width: 100%;
        position: absolute;
        height: 4px;
        bottom: 0;
        left: 0;
        background-color: $primaryLanding;
        box-shadow: 0 0 10px rgba(42, 243, 243, 0.55);
      }
      input {
        padding: 0;
        height: 30px;
        width: 100%;
        font-size: 28px;
        width: 100%;
        padding: 0;
        outline: none;
        color: $primaryLanding;
        font-weight: 600;
        border-radius: 0 !important;
        border: none !important;
        text-align: center;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        -moz-appearance: textfield;
      }
    }
  }
}
